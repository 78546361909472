import React from 'react';
import { styled } from "linaria/react";
import { theme } from "../Theme";
import { Intl } from '@jetshop/intl';
import { ReactComponent as IconSearch } from '../../svg/IconSearch.svg';

const SearchContainer = styled('div')`
  margin: -${theme.space[3]} auto 0 auto;
  position: relative;

  ${theme.below.sm} {
    margin: 0 auto;
  }

  input {
    width: 100%;
    height: 46px;
    padding: 0 48px 0 1.5rem;

    font-size: 0.75rem;
    background: ${theme.colors.greyLight};
    border: 1px solid ${theme.colors.beige};
    text-transform: uppercase;
    outline: none;

    &::placeholder {
      color: ${theme.colors.blueDark};
    }
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0.75rem;
    right: 1rem;

    ${theme.below.sm} {
      width: 1.15rem;
      height: 1.15rem;
      position: absolute;
      top: 0.875rem;
      right: 1rem;
    }
  }
`;

const StoreSearch = ({ search, setSearch }) => (
  <SearchContainer>
    <Intl>
      {t => (
        <input
          value={search}
          onChange={e => {
            setSearch(e.target.value);
          }}
          placeholder={t('Search')}
        />
      )}
    </Intl>
    <IconSearch />
  </SearchContainer>
);

export default StoreSearch;
