import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { fuzzySearch } from '@jetshop/ui/fuzzySearch';
import { Above } from '@jetshop/ui/Breakpoints';
import { distance } from '@jetshop/core/helpers/distance';

import storeLocatorQuery from './StoreLocator.gql';
import StoreSearch from './StoreSearch';
import StoreList from './StoreList';
import StoreMap from './StoreMap';
import { useLocationState } from './useLocationState';
import { MaxWidthSmall } from '../Layout/MaxWidth';

const StoreLocatorWrapper = styled('div')`
  ${theme.below.lg} {
    padding: 0;
  }
`;

const StoreLocatorContent = styled('div')`
  ${theme.below.lg} {
    height: unset;
  }
`;

const SearchAndListContainer = styled(MaxWidthSmall)`
  height: 100%;
`;

export const LocationStateContext = React.createContext(null);

const StoreLocator = () => {
  const locationState = useLocationState();
  const [search, setSearch] = useState('');
  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null
  });
  const [closestStore, setClosestStore] = useState(null);
  const [distances, setDistances] = useState({});

  const genDistances = stores => {
    if (userLocation.latitude == null) return;

    let distances = {};
    let closestStore = {};
    stores.forEach(store => {
      const thisDistance = distance(userLocation, store.coordinates);

      distances[store.id] = thisDistance;

      // If we haven't got a store in the closestStore obj yet, set it to this one
      if (!closestStore.distance) {
        closestStore = {
          ...store,
          distance: thisDistance
        };
        return;
      }

      // If this store is closer than the one currently stored in closestStore,
      // override it
      if (thisDistance < closestStore.distance) {
        closestStore = { ...store, distance: thisDistance };
      }
    });

    setDistances(distances);
    setClosestStore(closestStore);
  };

  return (
    <StoreLocatorWrapper>
      <StoreLocatorContent>
        <Query query={storeLocatorQuery}>
          {({ data, loading }) => {
            if (loading) return null;

            if (userLocation.latitude && !closestStore)
              genDistances(data.stores);

            const stores =
              search.length === 0
                ? data.stores
                : fuzzySearch({
                    data: data.stores,
                    keys: ['name', 'address1'],
                    searchTerm: search
                  });

            const storeSearchProps = { search, setSearch };
            const storeListProps = {
              stores,
              userLocation,
              setUserLocation,
              distances
            };
            const storeMapProps = {
              stores,
              userLocation,
              closestStore,
              distances
            };

            return (
              <LocationStateContext.Provider value={locationState}>
                <Above breakpoint="lg">
                  {matches =>
                    matches ? (
                      <>
                        <StoreMap {...storeMapProps} />
                        <SearchAndListContainer>
                          <StoreSearch {...storeSearchProps} />
                          <StoreList {...storeListProps} />
                        </SearchAndListContainer>
                      </>
                    ) : (
                      <>
                        <StoreMap {...storeMapProps} />
                        <StoreSearch {...storeSearchProps} />
                        <StoreList {...storeListProps} />
                      </>
                    )
                  }
                </Above>
              </LocationStateContext.Provider>
            );
          }}
        </Query>
      </StoreLocatorContent>
    </StoreLocatorWrapper>
  );
};

export default StoreLocator;
