import React from 'react';
import { Link } from 'react-router-dom';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { ReactComponent as IconChevron } from '../../svg/IconChevron.svg';

const StoreLinkCSS = styled(Link)`
  ${theme.mixins.smallHeading}
  color: ${theme.colors.blueDark};
  text-decoration: none;
  padding-top: 16px;

  > svg {
    position: relative;
    bottom: 1px;
    left: 0;
    margin-left: .5rem;
    transform: rotate(180deg);
    transition: left ease-in-out .2s;
    > path {
      stroke: ${theme.colors.blueDark};
    }
  }

  &:hover {
    > svg {
      left: 2px;
    }
  }
`;

const StoreLink = ({ storeID }) => (
  <StoreLinkCSS to={`/store/${storeID}`}>
    {t('Read more')}
    <IconChevron />
  </StoreLinkCSS>
);

export default StoreLink;
