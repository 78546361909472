import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../Theme';
import UserLocation from './UserLocation';
import StoreLink from './StoreLink';
import Button from '../ui/Button';
import { ContainerFullwidth } from '../Layout/Container';
import MaxWidth from '../Layout/MaxWidth';

const StoreListContainer = styled('ul')`
  > h1 {
    padding: ${theme.space[3]} 0 ${theme.space[3]} 0;
    ${theme.mixins.mediumHeading};
    text-align: center;
  }

  > button {
    display: block;
    width: 100%;
    max-width: 180px;
    margin: ${theme.space[4]} auto 0 auto;
    outline: 0;
  }

  ${theme.below.sm} {
    position: relative;
    padding: 0 ${theme.space[1]};
  }
`;

const StoreContainer = styled('li')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${theme.space[3]} ${theme.space[4]};
  margin: 8px 0;
  background: ${theme.colors.greyLight};
  border: 1px solid ${theme.colors.beige};
  outline: none;

  ${theme.below.sm} {
    display: block;
    padding: ${theme.space[2]};

    /* a {
      position: absolute;
      top: 0;
      right: 0;
    } */
  }

  h2 {
    ${theme.mixins.mediumHeading};
    margin-bottom: 1.375rem;

    ${theme.below.sm} {
      margin-bottom: 1rem;
    }
  }

  h3 {
    ${theme.mixins.smallHeading}
  }

  .column-contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    > div {
      display: flex;
      justify-content: space-between;
      > div:first-of-type {
        max-width: 304px;
      }
      > div:not(:first-of-type) {
        margin: 0 3rem 0 2rem;
      }
    }

    a {
      color: ${theme.colors.primary};
      text-decoration: none;
    }
  }

  .adress-column {
    p {
      margin-bottom: 0;
    }
  }

  .column-details {
    width: 160px;
    flex: 0 1 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin-bottom: 0.325rem;
    }

    ${theme.below.sm} {
      width: 100%;
      flex: 1 1 100%;
      position: relative;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledMaxWidth = styled(MaxWidth)`
  max-width: 1120px;

  > ul {
    column-count: 4;
    ${theme.below.sm} {
      column-count: 1;
    }
    > li {
      margin-bottom: 0.5rem;
      line-height: 1.25rem;
      ${theme.below.sm} {
        width: 100%;
        flex: 0 1 100%;
      }

      > a {
        font-size: 0.875rem;
        color: ${theme.colors.blueDark};
        text-decoration: none;
        transition: color ease-in-out 0.2s;
        &:hover {
          position: relative;
          color: ${theme.colors.blue};
          text-decoration: underline;

          &:after {
            content: '';
            display: block;
            width: 0.625rem;
            height: 1.2rem;
            position: absolute;
            right: -1.15rem;
            top: 0;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.281' height='11.315' viewBox='0 0 6.281 11.315'%3E%3Cpath d='M580.5,382.75l4.38,4.38a.306.306,0,0,1,0,.433l-4.38,4.38' transform='translate(-579.439 -381.689)' fill='none' stroke='%2340689a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E")
              no-repeat center;
          }
        }
      }
    }
  }
`;

const StyledContainerFullwidth = styled(ContainerFullwidth)`
  margin-top: ${theme.space[6]};

  &:before {
    content: '';
    display: block;
    width: 100%;
    max-width: 1304px;
    padding: 0 2rem;
    border-top: 1px solid ${theme.colors.beige};
    margin: 0 auto;
    margin-bottom: ${theme.space[5]};
    ${theme.below.sm} {
      margin-bottom: ${theme.space[3]};
    }
  }

  h2 {
    margin-bottom: ${theme.space[5]};
    font-size: 2.25rem;
    line-height: 2.75rem;
    font-weight: ${theme.fontWeights.medium};
    text-align: center;

    ${theme.below.sm} {
      font-size: 1.625rem;
      line-height: 2rem;
      margin-bottom: ${theme.space[3]};
    }
  }
`;

// const humanDistance = distance =>
//   distance > 10
//     ? t('{km} km', { km: Math.round(distance) })
//     : distance > 1
//     ? t('{km} km', { km: distance.toFixed(1) })
//     : t('{m} m', { m: Math.round(distance * 1000) });

// const contactParser = html =>
//   html
//     .replace('Tel: ', '<span class="contact-icon">📞</span>')
//     .replace('Email: ', '<span class="contact-icon">🖂</span>');

const Store = ({
  distances,
  store,
  store: { id, name, address1, openHours, description, coordinates, contact }
}) => {
  const telRegex = contact.match(/telefon: ([^<]+)/i);
  const tel = telRegex && telRegex[1] ? telRegex[1] : null;
  // const addressRegex = address1.match(/<p[^>]*>([\s\S]*?)<\/p>/g);
  // const address =
  //   addressRegex && addressRegex.length > 0 ? addressRegex.pop() : null;
  const address = address1 ? address1 : null;

  return (
    <StoreContainer tabIndex={0}>
      <div className="column-contact">
        <Link to={`butik/${store.id}`}>
          <h2 dangerouslySetInnerHTML={{ __html: name }} />
        </Link>
        <Above breakpoint="lg">
          {matches =>
            matches && (
              <div>
                {address && (
                  <div className="adress-column">
                    <h3>{t('Address')}</h3>
                    <div dangerouslySetInnerHTML={{ __html: address }} />
                  </div>
                )}

                {tel && (
                  <div>
                    <h3>{t('Phone')}</h3>
                    <div dangerouslySetInnerHTML={{ __html: tel }} />
                  </div>
                )}
              </div>
            )
          }
        </Above>
      </div>
      <div className="column-details">
        {openHours && (
          <div>
            <h3>{t('Opening hours')}</h3>
            <div dangerouslySetInnerHTML={{ __html: openHours }} />
          </div>
        )}
        <StoreLink storeID={id} />
      </div>
    </StoreContainer>
  );
};

const StoreList = ({ stores, userLocation, setUserLocation, distances }) => {
  const offset = 5;
  const [storeOffset, setStoreOffset] = useState(offset);
  const storesCount = stores && stores.length;

  if (!storesCount) return <h1>{t('Stores')}</h1>;

  const sortStores = [...stores].sort((a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
  );
  const showStores = sortStores.slice(0, storeOffset);

  return (
    <>
      <StoreListContainer>
        <h1>{t('Stores')}</h1>
        <UserLocation setUserLocation={setUserLocation} />
        {showStores &&
          showStores.map((store, index) => (
            <Store key={store.id} store={store} distances={distances} />
          ))}
        {storeOffset >= storesCount || (
          <Button onClick={() => setStoreOffset(so => so + offset)}>
            {t('Show more')}
          </Button>
        )}
      </StoreListContainer>
      <StyledContainerFullwidth>
        <h2>{t('All stores')}</h2>
        <StyledMaxWidth>
          <ul>
            {sortStores.map(store => (
              <li key={store.id}>
                <Link to={`butik/${store.id}`}>
                  <span dangerouslySetInnerHTML={{ __html: store.name }} />
                </Link>
              </li>
            ))}
          </ul>
        </StyledMaxWidth>
      </StyledContainerFullwidth>
    </>
  );
};

export default StoreList;
