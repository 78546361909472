import React, { PureComponent } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import StoreMap from '../StoreLocator/StoreMap';
import MaxWidth from '../Layout/MaxWidth';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import { theme } from '../Theme';
import ContentShortcodes from '../Theme/Shortcodes/ContentShortcodes';
import Banner from '../Theme/Shortcodes/Banner';
import { stripTags } from '../Theme/Utils/common';
import { extractShortcode } from '../Theme/Utils/extractShortcodes';
import { Helmet } from 'react-helmet-async';

const StoreInfo = styled('div')`
  display: flex;
  justify-content: flex-start;
  padding: ${theme.space[6]} 0;

  ${theme.below.lg} {
    flex-direction: column;
  }

  ${theme.below.sm} {
    display: block;
    padding: ${theme.space[3]} 0;
  }

  > .store-intro {
    flex: 0 1 auto;
    max-width: 568px;
    padding-right: 4rem;

    ${theme.below.lg} {
      max-width: 100%;
    }

    ${theme.below.sm} {
      max-width: none;
      padding-right: 0;
    }
  }

  > .store-details {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    ${theme.below.lg} {
      justify-content: space-between;
    }

    ${theme.below.sm} {
      display: block;
      max-width: none;
    }

    h3 {
      display: block;
      font-size: 1rem;
      font-weight: ${theme.fontWeights.medium};
      margin-bottom: 0.875rem;
    }

    p {
      font-size: 0.875rem;
      margin-bottom: 0;
      font-weight: ${theme.fontWeights.book};
    }

    a {
      color: ${theme.colors.primary};
    }

    > div {
      width: 280px;

      ${theme.below.lg} {
        margin-right: 0;
        margin-top: 3rem;
      }

      ${theme.below.sm} {
        margin-right: 0;
        margin-top: 1.5rem;
        flex: 0 1 100%;
        width: 100%;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;

const StoreMapContainer = styled('div')`
  margin-bottom: ${theme.space[7]};
`;

const StoreMeta = ({ store }) => {
  // Svensk meta
  const title = `Välkommen till Smycka ${store.name}`;
  const description = store.address2
    ? store.address2
    : 'Smycken och klockor för alla tillfällen. Välkommen att handla i Butiken. Med känsla för det vackra.';

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description}></meta>
    </Helmet>
  );
};

export default class StoreDetail extends PureComponent {
  render() {
    const { store, userLocation } = this.props;
    const stores = [store];

    const storeMapProps = {
      stores,
      userLocation
    };

    let content;
    let bannerShortcode;

    if (store.description) {
      content = stripTags(store.description);
      bannerShortcode = extractShortcode(content, 'Banner');

      if (bannerShortcode) {
        content = content.replace(bannerShortcode, '');
      }
    }

    return (
      <>
        <StoreMeta {...{ store }} />
        {bannerShortcode && (
          <Shortcodes content={bannerShortcode} components={{ Banner }} />
        )}
        <MaxWidth>
          <StoreInfo>
            {store.other && (
              <div
                className="store-intro"
                dangerouslySetInnerHTML={{ __html: store.other }}
              />
            )}
            <div className="store-details">
              {store.openHours && (
                <div>
                  <h3>{t('Opening hours')}</h3>
                  <div dangerouslySetInnerHTML={{ __html: store.openHours }} />
                </div>
              )}
              {store.contact && (
                <div>
                  <h3>{t('Contact store')}</h3>
                  <div dangerouslySetInnerHTML={{ __html: store.contact }} />
                </div>
              )}
              {store.address1 && (
                <div>
                  <h3>{t('Find store')}</h3>
                  <div dangerouslySetInnerHTML={{ __html: store.address1 }} />
                </div>
              )}
            </div>
          </StoreInfo>
          <StoreMapContainer>
            <StoreMap {...storeMapProps} />
          </StoreMapContainer>
          {content && (
            <Shortcodes
              content={content}
              components={{
                ...ContentShortcodes
              }}
            />
          )}
        </MaxWidth>
      </>
    );
  }
}
