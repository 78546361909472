import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import useGeolocation from '@jetshop/core/hooks/useGeolocation';
import MaxWidth from '../Layout/MaxWidth';
import { LocationStateContext } from '../StoreLocator/StoreLocator';
import { useLocationState } from '../StoreLocator/useLocationState';
import StoreDetail from './StoreDetail';
import StoreQuery from './StoreQuery.gql';

const Wrapper = styled(MaxWidth)`
  margin: 2rem;
`;

const Store = props => {
  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null
  });

  useGeolocation({
    onSuccess: ({ coords: { latitude, longitude } }) =>
      setUserLocation({ latitude, longitude }),
    onError: err => console.log(err)
  });

  const locationState = useLocationState();

  const {
    match: {
      params: { id: storeId }
    }
  } = props;
  return (
    <Query query={StoreQuery} variables={{ id: storeId }}>
      {({ data, loading, error }) => {
        if (loading) return <Wrapper>Loading…</Wrapper>;
        if (error) return 'Something went wrong';

        const { store } = data;

        return !store ? (
          'No store found!'
        ) : (
          <LocationStateContext.Provider value={locationState}>
            <StoreDetail store={store} userLocation={userLocation} />
          </LocationStateContext.Provider>
        );
      }}
    </Query>
  );
};

export default Store;
