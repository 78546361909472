import { stripTags } from './common';
// const shortcodes = content.match(/\[[^[^\]]+\]/g); // Each shortcode
// const shortcodes = content.match(/\[(.*)\]/g); // All shortcodes, new match for each /n
// const shortcodes = content.match(/\[(.*)\]/sg); // Full match 1
// const shortcodes = content.match(/\[(.+)((\s)+(.+))+\]/g); // Full match 2

const extractShortcodes = source => {
  if (!source) return false;

  const shortcodesMatch = source.match(/\[(.*)\]/gs);
  const shortcodes =
    shortcodesMatch && shortcodesMatch[0] ? shortcodesMatch[0] : null;
  const rest =
    shortcodes && shortcodes.length > 0 ? source.replace(shortcodes, '') : null;

  if (shortcodes) {
    return [stripTags(shortcodes), rest];
  } else {
    return [null, source];
  }
};

export const extractShortcode = (source, shortcodeName) => {
  if (!source || !shortcodeName) return false;
  const regex = new RegExp(`\\[${shortcodeName} [^[^\\]]+\\]`);
  const result = source.match(regex);

  return result && result[0] ? result[0] : false;
};

export default extractShortcodes;
